<template>
  <v-data-table
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="options"
    calculate-widths
    height="calc(100vh - 254px)"
    hide-default-footer
    :server-items-length="1"
    disable-filtering
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar dense class="custom-toolbar" flat>
        <v-toolbar-title style="font-size: 15px"
          >Tổng: {{ pagination.total }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('handle-create')"
          class="mx-2"
          x-small
          fab
          dark
          color="indigo"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          @click="$emit('handle-export')"
          class="mx-2"
          x-small
          fab
          dark
          color="indigo"
        >
          <v-icon dark>mdi-download</v-icon>
        </v-btn>
        <v-edit-dialog ref="file" @close="file = null">
          <v-btn class="mx-2" x-small fab dark color="indigo">
            <v-icon>mdi-upload</v-icon></v-btn
          >

          <template v-slot:input>
            <div class="text-center py-2">
              <v-file-input
                label="File"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                prepend-icon=""
                append-icon="mdi-file-outline"
                @change="file = $event"
                :value="file"
                style="width: 250px"
                hide-details
              ></v-file-input>
              <p class="pt-1">
                <v-btn x-small color="green" @click="downloadTemplate" text
                  >Tải file template</v-btn
                >
              </p>

              <v-btn
                class="my-1"
                color="blue"
                @click="importExcel"
                :disabled="!file"
                :loading="loading"
                small
                >Lưu</v-btn
              >
              <v-btn
                class="my-1"
                :disabled="loading"
                color="blue"
                @click="cancelUpload"
                small
                >Hủy</v-btn
              >
            </div>
          </template>
        </v-edit-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <p class="my-0">{{ item.name }}</p>
      <p class="my-0" style="font-size: 11px">
        {{ item.address }}
      </p>
    </template>
    <template v-slot:item.status="{ item }">
      <span v-if="item.status == '1'">Đang hoạt động</span>
      <span v-else-if="item.status == '2'">Tạm thời đóng cửa</span>
      <span v-else>Đã ngừng hoạt động</span>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        x-small
        @click="$emit('handle-edit', item)"
        fab
        dark
        color="primary"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        class="ml-2"
        x-small
        @click="handleDelete(item.id)"
        fab
        dark
        color="error"
      >
        <v-icon dark>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="$emit('handle-reset')">Refresh</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { destroy, importData } from "@/api/place";
import dataTableMixin from "@/mixins/crud/data-table";
export default {
  mixins: [dataTableMixin(destroy)],
  computed: {
    headers() {
      return [
        { text: "Tên", value: "name" },
        { text: "Mã", value: "code" },
        { text: "Tỉnh thành", value: "state.name", sortable: false },
        { text: "Quận huyện", value: "district.name", sortable: false },
        { text: "Trạng thái hoạt động", value: "status" },
        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
          width: 110,
        },
      ];
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    async importExcel() {
      if (
        this.file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return this.$snackbar("Chỉ hỗ trợ file excel", "error");
      try {
        this.loading = true;
        const form = new FormData();
        form.append("file", this.file);
        form.append("organizationCode", this.$route.params.code);
        const { data } = await importData(form);
        this.$snackbar(
          `Cập nhật thành công ${data.updatedPlace} địa điểm`,
          "success"
        );
        this.loading = false;
        this.$refs["file"].cancel();
        this.$emit("imported");
      } catch (error) {
        this.loading = false;
      }
    },
    cancelUpload(ref) {
      this.$refs["file"].cancel();
    },
    downloadTemplate() {
      window.open("/template/place.xlsx", "_blank");
    },
  },
};
</script>
