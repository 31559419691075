<template>
  <v-dialog v-model="showMapDialog" max-width="750px" eager>
    <v-card>
      <v-card-title>Chỉnh sửa vị trí</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div style="height: 400px; width: 100%" id="map">
              <v-icon color="red">mdi-map-marker</v-icon>
            </div>
            <span style="font-size: 12px"
              >(*) Di chuyển bản đồ để xác định vị trí</span
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="position.lng"
              hide-details
              label="Kinh độ"
              @change="setCenter"
            ></v-text-field>
            <span style="color: red; font-size: 12px"
              >(*) Tuân theo hệ tọa độ quốc tế wgs84</span
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="position.lat"
              hide-details
              @change="setCenter"
              label="Vĩ độ"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-btn @click="reset" color="blue" class="d-block mx-auto mt-3" text
          >Đặt lại bản đồ</v-btn
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn color="blue darken-1" text @click="updatePosition">Lưu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mapboxgl from "mapbox-gl";
export default {
  props: ["showMapDialog", "form"],
  data() {
    return {
      map: null,
      position: {
        lat: "",
        lng: "",
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("update:showMapDialog", false);
    },
    updatePosition() {
      this.$emit("change", this.position);
      this.closeDialog();
    },
    reset() {
      this.map.setCenter([this.form.lng, this.form.lat]);
    },
    setCenter() {
      this.map.setCenter([this.position.lng, this.position.lat]);
    },
  },
  watch: {
    showMapDialog(val) {
      if (val) {
        this.$nextTick(() => this.map.resize());
        this.map.setCenter([this.form.lng, this.form.lat]);
        this.position.lat = this.form.lat;
        this.position.lng = this.form.lng;
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZHVuZ25rbmQ5NyIsImEiOiJja242djJ0NngwYzVzMm9uemQ4dXp0YjVjIn0.mmHHB2p3aXVZVsZzxpt96w";
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.form.lng, this.form.lat],
      zoom: 15,
      minZoom: 5.4,
    });
    this.map.on("move", () => {
      const { lat, lng } = this.map.getCenter();
      this.position.lat = parseFloat(lat).toFixed(7);
      this.position.lng = parseFloat(lng).toFixed(7);
    });
  },
};
</script>
<style lang="scss">
#map {
  position: relative;
  .v-icon {
    position: absolute;
    top: 175px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
  }
}
</style>
