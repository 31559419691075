import request from "@/utils/request";

export function getStates() {
  return request({
    url: "/states",
    method: "get"
  });
}
export function getDistricts(code) {
  return request({
    url: "/states/" + code + "/districts",
    method: "get"
  });
}
export function getSubDistricts(code) {
  return request({
    url: "/districts/" + code + "/sub-districts",
    method: "get"
  });
}
