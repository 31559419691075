import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/place-types",
    method: "get",
    params
  });
}
export function update(id, data) {
  return request({
    url: "/place-types/" + id,
    method: "put",
    data
  });
}
export function show(id) {
  return request({
    url: "/place-types/" + id,
    method: "get"
  });
}
export function store(data) {
  return request({
    url: "/place-types",
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: "/place-types/" + id,
    method: "delete"
  });
}
