import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/places",
    method: "get",
    params
  });
}
export function update(id, data) {
  return request({
    url: "/places/" + id,
    method: "put",
    data
  });
}
export function show(id) {
  return request({
    url: "/places/" + id,
    method: "get"
  });
}
export function store(data) {
  return request({
    url: "/places",
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: "/places/" + id,
    method: "delete"
  });
}
export function exportData(params) {
  return request({
    url: "/places/export",
    method: "get",
    params,
    responseType: "blob"
  });
}
export function importData(data) {
  return request({
    url: "/places/import",
    method: "post",
    data
  });
}
