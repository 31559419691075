<template>
  <v-dialog v-model="showWorkTimeDialog" max-width="500px" eager>
    <v-card>
      <v-card-title>Giờ mở cửa</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          disable-pagination
          disable-filtering
          disable-sort
          hide-default-footer
          :items="workTimes"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.type="{ item }">
            <v-chip v-if="item.type === 'all_day'" color="green" dark>
              Cả ngày
            </v-chip>
            <v-chip v-else-if="item.type === 'range'" color="blue" dark>
              Theo giờ
            </v-chip>
            <v-chip v-else color="warning" dark> Đóng cửa </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="showDialog(item)" color="success"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Chỉnh sửa giờ làm việc</span>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-container class="pt-0">
                <v-select
                  label="Kiểu"
                  :items="[
                    { type: 'all_day', name: 'Cả ngày' },
                    { type: 'closed', name: 'Đóng cửa' },
                    { type: 'range', name: 'Chọn giờ' },
                  ]"
                  item-text="name"
                  item-value="type"
                  v-model="workTime.type"
                >
                </v-select>
                <template v-if="workTime.type === 'range'">
                  <TimePicker
                    :hideDetails="false"
                    :value.sync="workTime.openAt"
                    label="Giờ mở cửa"
                  />
                  <TimePicker
                    :hideDetails="false"
                    :value.sync="workTime.closeAt"
                    label="Giờ đóng cửa"
                  />
                </template>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Hủy
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Lưu </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('update:showWorkTimeDialog', false)"
        >
          Lưu
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TimePicker from "@/components/TimePicker";
export default {
  props: ["showWorkTimeDialog", "workTimes"],
  components: { TimePicker },
  data() {
    return {
      dialog: false,
      workTime: {
        weekday: "2",
        type: "all_day",
        openAt: null,
        closeAt: null,
      },
      headers: [
        { text: "Thứ", value: "weekday", align: "center" },
        { text: "Loại", value: "type", align: "center" },
        { text: "Giờ mở cửa", value: "openAt" },

        { text: "Giờ đóng cửa", value: "closeAt" },

        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
          width: 110,
        },
      ],
    };
  },
  methods: {
    showDialog(item) {
      this.workTime = { ...item };
      this.dialog = true;
    },
    save() {
      if (
        this.workTime.type === "range" &&
        (!this.workTime.openAt || !this.workTime.closeAt)
      ) {
        return this.$snackbar(
          "Bạn chưa chọn giờ mở cửa và giờ đóng cửa",
          "error"
        );
      }
      const i = this.workTimes.findIndex(
        (w) => w.weekday === this.workTime.weekday
      );
      for (const field in this.workTime) {
        this.workTimes[i][field] = this.workTime[field];
      }
      this.dialog = false;
    },
  },
};
</script>
