<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label || 'Time'"
        :dense="dense"
        readonly
        v-bind="attrs"
        :clearable="clearable"
        :hide-details="hideDetails"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="timeLocal"
      format="24hr"
      @click:minute="menu = false"
    ></v-time-picker>
  </v-menu>
</template>
<script>
export default {
  props: ["value", "clearable", "label", "hideDetails", "dense"],
  data: () => ({
    menu: false,
    timeLocal: null
  }),
  watch: {
    timeLocal(val) {
      this.$emit("update:value", val);
      this.$emit("change");
    }
  },
  computed: {
    dateRangeText: {
      set(val) {
        this.timeLocal = "";
      },
      get() {
        if (!this.value) return "";
        return this.timeLocal;
      }
    }
  },
  mounted() {
    this.timeLocal = this.value;
  }
};
</script>
