<template>
  <v-dialog
    @keyup.esc="closeDialog"
    v-model="dialog"
    class="place-dialog"
    max-width="750px"
    eager
  >
    <v-card>
      <v-app-bar
        absolute
        color="white"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title
          >{{ editing ? "Chỉnh sửa" : "Thêm mới" }} địa điểm</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="800"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container class="px-0" style="padding-top: 64px">
            <v-divider></v-divider>
            <div class="place-form">
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-home-modern</v-icon>
                </div>

                <div class="form-field pl-2">
                  <label>Tên</label>
                  <v-text-field
                    dense
                    v-model="form.name"
                    placeholder="Nhập tên địa điểm"
                    :rules="[(v) => !!v || 'Bạn chưa nhập tên địa điểm']"
                  ></v-text-field>
                </div>
              </div>

              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-home-modern</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Mã</label>
                  <v-text-field
                    dense
                    v-model="form.code"
                    placeholder="Nhập mã địa điểm"
                    :rules="[(v) => !!v || 'Bạn chưa nhập mã địa điểm']"
                  ></v-text-field>
                </div>
              </div>

              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-shape</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Loại</label>
                  <v-select
                    dense
                    v-model="form.placeTypeId"
                    :items="placeTypes"
                    item-text="name"
                    item-value="id"
                    placeholder="Chọn loại địa điểm"
                    :rules="[(v) => !!v || 'Bạn chưa chọn loại địa điểm']"
                  ></v-select>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-map-marker</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Tỉnh thành</label>
                  <v-autocomplete
                    dense
                    v-model="form.stateId"
                    :items="states"
                    @change="handleStateChange"
                    item-text="name"
                    item-value="id"
                    placeholder="Chọn tỉnh thành"
                    :rules="[(v) => !!v || 'Bạn chưa chọn tỉnh thành']"
                  ></v-autocomplete>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-map-marker</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Quận huyện</label>
                  <v-autocomplete
                    dense
                    v-model="form.districtId"
                    :items="districts"
                    item-text="name"
                    item-value="id"
                    @change="handleDistrictChange"
                    placeholder="Chọn quận huyện"
                    :rules="[(v) => !!v || 'Bạn chưa chọn quận huyện']"
                  ></v-autocomplete>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-map-marker</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Phường xã</label>
                  <v-autocomplete
                    dense
                    v-model="form.subDistrictId"
                    :items="subDistricts"
                    item-text="name"
                    item-value="id"
                    placeholder="Chọn phường xã"
                    @change="handleSubDistrictChange"
                    :rules="[(v) => !!v || 'Bạn chưa chọn phường xã']"
                  ></v-autocomplete>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-map-marker</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Vị trí</label>
                  <v-text-field
                    v-model="form.address"
                    dense
                    placeholder="Nhập địa chỉ"
                    :rules="[(v) => !!v || 'Bạn chưa nhập địa chỉ']"
                  ></v-text-field>
                </div>
              </div>

              <div class="place-form-input">
                <div class="form-icon"></div>
                <div class="form-field pl-2">
                  <div
                    id="map-preview"
                    @click="showMapDialog = true"
                    style="height: 100px"
                  >
                    <v-icon color="red">mdi-map-marker</v-icon>
                  </div>
                </div>
              </div>
              <div class="place-form-input mt-4">
                <div class="form-icon">
                  <v-icon>mdi-clock-outline</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Giờ mở cửa</label>
                  <v-textarea
                    ref="workTime"
                    dense
                    @click="showWorkTimeDialog = true"
                    :value="workTimesText"
                  ></v-textarea>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-headphones</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Hotline</label>
                  <v-text-field
                    v-model="form.hotline"
                    dense
                    placeholder="Nhập hotline"
                  ></v-text-field>
                </div>
              </div>
              <div class="place-form-input mt-4">
                <div class="form-icon">
                  <v-icon>mdi-phone</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label
                    >Liên hệ
                    <v-icon @click="phoneNumbers.push('')" style="float: right"
                      >mdi-plus</v-icon
                    >
                  </label>
                  <div
                    v-for="(phoneNumber, key) in phoneNumbers"
                    :key="key"
                    style="position: relative"
                  >
                    <v-text-field
                      v-model="phoneNumbers[key]"
                      dense
                      placeholder="Nhập số điện thoại liên hệ"
                    ></v-text-field>
                    <v-icon
                      @click="phoneNumbers.splice(key, 1)"
                      style="position: absolute; right: 0; top: 0"
                      >mdi-close</v-icon
                    >
                  </div>
                </div>
              </div>

              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-web</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Website</label>
                  <v-text-field
                    v-model="form.website"
                    dense
                    placeholder="Nhập trang web"
                  ></v-text-field>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-calendar-range</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Ngày thành lập</label>

                  <DatePicker
                    placeholder="Chọn ngày thành lập"
                    :value.sync="form.founding"
                    dense
                  ></DatePicker>
                </div>
              </div>
              <div class="place-form-input">
                <div class="form-icon">
                  <v-icon>mdi-list-status</v-icon>
                </div>
                <div class="form-field pl-2">
                  <label>Trạng thái hoạt động</label>
                  <v-select
                    dense
                    v-model="form.status"
                    :items="[
                      { id: '1', name: 'Đang hoạt động' },
                      { id: '2', name: 'Tạm thời đóng cửa' },
                      { id: '3', name: 'Đã ngừng hoạt động' },
                    ]"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </div>
              </div>
              <div class="place-form-input mt-5">
                <div class="form-icon"></div>

                <div class="form-field pl-2 d-flex">
                  <v-btn
                    v-if="images.length === 0"
                    @click="handleUpload"
                    block
                    outlined
                    color="blue"
                    ><v-icon class="mr-2">mdi-image-plus</v-icon> Thêm
                    ảnh</v-btn
                  >
                  <template v-else>
                    <div class="upload" @click="handleUpload">
                      <v-icon>mdi-image-plus</v-icon>
                    </div>
                    <div
                      v-for="(image, key) in images"
                      :key="key"
                      class="image"
                      :style="{
                        'background-image': `url(${image})`,
                      }"
                    >
                      <v-icon @click="images.splice(key, 1)" color="white"
                        >mdi-close</v-icon
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </v-container>
        </v-form>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn
          v-if="!editing"
          color="blue darken-1"
          :loading="loading"
          text
          @click="createData"
          >Thêm</v-btn
        >
        <v-btn v-else color="blue darken-1" text @click="updateData"
          >Cập nhật</v-btn
        >
      </v-card-actions>
    </v-card>
    <Map
      @change="updatePosition"
      :showMapDialog.sync="showMapDialog"
      :form="form"
    />
    <WorkTime
      :showWorkTimeDialog.sync="showWorkTimeDialog"
      :workTimes="workTimes"
    />
    <input
      style="display: none"
      @change="handleFileChanged"
      type="file"
      ref="image"
      accept="image/png, image/jpeg"
    />
  </v-dialog>
</template>
<script>
import { store, update } from "@/api/place";
import dialogMixin from "@/mixins/crud/dialog";
import mapboxgl from "mapbox-gl";
import Map from "./Map";
import WorkTime from "./WorkTime";
import { index as getPlaceTypes } from "@/api/place-type";
import DatePicker from "@/components/DatePicker";
import { getStates, getDistricts, getSubDistricts } from "@/api/adm";
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const workTimeType = {
  all_day: "Cả ngày",
  closed: "Đóng cửa",
  range: "Theo giờ",
};
export default {
  mixins: [dialogMixin(store, update)],
  components: {
    WorkTime,
    Map,
    DatePicker,
  },

  computed: {
    title() {
      return !this.editing ? "Thêm mới" : "Cập nhật";
    },
    workTimesText() {
      return this.workTimes
        .map(
          (w) =>
            `${w.weekday !== "CN" ? "Thứ" : ""} ${w.weekday}: ${
              w.type !== "range"
                ? workTimeType[w.type]
                : w.openAt + " - " + w.closeAt
            }`
        )
        .join("\n");
    },
  },

  data() {
    return {
      map: null,
      showMapDialog: false,
      loading: false,
      showWorkTimeDialog: false,
      placeTypes: [],
      images: [],
      file: null,
      workTimes: [],
      phoneNumbers: [""],
      states: [],
      districts: [],
      subDistricts: [],
    };
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.$nextTick(() => this.map.resize());
        this.map.setCenter([this.form.lng, this.form.lat]);
        if (!this.editing) {
          this.workTimes = [];
          for (const day of ["2", "3", "4", "5", "6", "7", "CN"]) {
            this.workTimes.push({
              weekday: day,
              type: "all_day",
              openAt: null,
              closeAt: null,
            });
          }
          this.images = [];
          this.phoneNumbers = [""];
        } else {
          this.images = this.form.images.map((item) => item.link);
          this.phoneNumbers = this.form.contacts
            .filter((item) => item.type === "phone")
            .map((item) => item.value);

          this.form.website =
            this.form.contacts.find((item) => item.type === "website")?.value ||
            "";
          this.workTimes = JSON.parse(JSON.stringify(this.form.workTimes));
          this.getDistricts(
            this.states.find((s) => s.id === this.form.stateId).code
          ).then((res) => {
            this.getSubDistricts(
              this.districts.find((s) => s.id === this.form.districtId).code
            );
          });
        }
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZHVuZ25rbmQ5NyIsImEiOiJja242djJ0NngwYzVzMm9uemQ4dXp0YjVjIn0.mmHHB2p3aXVZVsZzxpt96w";
    this.map = new mapboxgl.Map({
      container: "map-preview",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.form.lng, this.form.lat],
      zoom: 12,
      minZoom: 5.4,
      interactive: false,
      states: [],
    });
    this.getPlaceTypes();
    this.getStates();
  },
  methods: {
    updatePosition(e) {
      this.form.lat = e.lat;
      this.form.lng = e.lng;
      this.map.setCenter([this.form.lng, this.form.lat]);
    },
    async getPlaceTypes() {
      this.placeTypes = (
        await getPlaceTypes({
          organizationCode: this.$route.params.code,
          sortBy: "name",
        })
      ).data;
    },
    async getStates() {
      this.states = (await getStates()).data;
    },
    async getDistricts(code) {
      this.districts = (await getDistricts(code)).data;
    },
    async getSubDistricts(code) {
      this.subDistricts = (await getSubDistricts(code)).data;
    },
    handleUpload() {
      this.$refs.image.click();
    },
    async handleFileChanged(e) {
      const file = e.target.files[0];
      if (!["image/png", "image/jpeg"].includes(file.type)) {
        this.$refs.image.value = null;
        return this.$snackbar("Hãy up 1 file ảnh", "error");
      }
      const image = await toBase64(file);
      this.images.push(image);
      this.$refs.image.value = null;
    },
    async createData() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) return;
        this.loading = true;
        await store({
          ...this.form,
          workTimes: this.workTimes,
          images: this.images,
          phoneNumbers: this.phoneNumbers,
        });
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    },
    async updateData() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) return;
        this.loading = true;
        await update(this.form.id, {
          ...this.form,
          images: this.images,
          workTimes: this.workTimes,
          organizationCode: this.$route.params.code,
          phoneNumbers: this.phoneNumbers,
        });
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    },
    reload() {
      this.loading = false;
      this.$snackbar(
        this.editing ? "Cập nhật thành công" : "Thêm mới thành công",
        "success"
      );
      this.closeDialog();
      this.$emit(this.editing ? "updated" : "created");
    },
    handleStateChange(val) {
      if (val) {
        this.form.districtId = "";
        this.getDistricts(this.states.find((s) => s.id === val).code);
      }
    },
    handleDistrictChange(val) {
      if (val) {
        this.form.subDistrictId = "";
        this.getSubDistricts(this.districts.find((s) => s.id === val).code);
      }
    },
    handleSubDistrictChange(val) {
      if (val) {
        const [lng, lat] = this.subDistricts.find((s) => s.id === val).center;
        this.updatePosition({ lng, lat });
      }
    },
  },
};
</script>
<style lang="scss">
.place-form {
  padding: 20px;
  label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
  }
  .place-form-input {
    margin-top: 4px;
    display: flex;
    .form-icon {
      width: 30px;
      .v-icon {
        font-size: 25px;
      }
    }
    .form-field {
      width: calc(100% - 30px);
      .upload {
        width: 70px;
        height: 70px;
        position: relative;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: #eceff1;
        }
        .v-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .image {
        width: 70px;
        height: 70px;
        background-size: cover;
        margin-left: 20px;
        position: relative;
        .v-icon {
          position: absolute;
          right: 4px;
          top: 4px;
          font-weight: bold;
          border-radius: 50%;
          background: #757575;
        }
      }
    }
  }
}
.place-section {
  color: rgba(0, 0, 0, 0.54);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-left: 58px;
  margin-bottom: 14px;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}
#map-preview {
  cursor: pointer;
  position: relative;
  .v-icon {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30px;
  }
}
</style>
