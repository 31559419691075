<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <v-row>
      <v-col class="py-0" cols="12">
        <Search
          :params="params"
          @handle-search="getData(1)"
          @handle-reset="reset"
        />
      </v-col>
      <v-col class="py-0" cols="12">
        <DataTable
          :form="form"
          :table-data="tableData"
          @handle-edit="showDialogForm('edit', $event)"
          @handle-create="showDialogForm('create')"
          @deleted="getData()"
          @imported="getData()"
          :pagination="pagination"
          @sorted="handleSort"
          @handle-export="exportData"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <Pagination
          :length="pagination.lastPage"
          :params="params"
          @handle-change-page="getData"
          @handle-change-per-page="getData(1)"
        />
      </v-col>
    </v-row>
    <DialogForm
      @created="getData(1)"
      @updated="getData"
      :options="options"
      :show-dialog.sync="showDialog"
      :editing="editing"
      :form="form"
    />
  </v-container>
</template>

<script>
import DataTable from "./components/DataTable";
import Search from "./components/Search";
import DialogForm from "./components/DialogForm";
import Pagination from "@/components/Pagination";
import { index, exportData } from "@/api/place";
import { getStates, getDistricts } from "@/api/adm";

import indexMixin from "@/mixins/crud/index";
export default {
  mixins: [indexMixin(index, {}, exportData)],
  components: { DataTable, Search, DialogForm, Pagination },
  data() {
    const code = this.$route.params.code;

    return {
      showDialog: false,
      editing: false,
      defaultParams: {
        search: "",
        page: 1,
        perPage: 20,
        sortBy: "code",
        organizationCode: code,
        status: "",
        with: "placeType:id,name;contacts;workTimes:weekday,closeAt,openAt,type;images:id,link;state:id,name;district:id,name",
      },
      form: {
        id: undefined,
        lat: 21.03707751927323,
        lng: 105.83469888906326,
        name: "",
        code: "",
        placeTypeId: "",
        stateId: "",
        districtId: "",
        subDistrictId: "",
        address: "",
        website: "https://viettelstore.vn",
        organizationCode: code,
        founding: null,
        hotline: "18008123",
        status: "1",
      },
    };
  },
  methods: {
    showDialogForm(mode, data = null) {
      if (mode == "edit") {
        this.editing = true;
        for (let field in this.form) {
          this.form[field] = data[field];
        }
        this.form.workTimes = [...data.workTimes];
        this.form.contacts = [...data.contacts];
        this.form.images = [...data.images];
        console.log(this.form);
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm));
        this.form.id = undefined;
        this.editing = false;
      }
      this.showDialog = true;
    },
  },
};
</script>
